@import url('https://fonts.googleapis.com/css?family=Raleway');

body {
	font-family: 'Raleway', serif;
	font-size: 1.5em;
}

footer {
  background-color: #292c2f;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  font: 700 16px sans-serif;
  padding: 55px 50px;
  margin-top: 0px;
  bottom: 0;
  color: #fff;
	@media(max-width:767px){
		padding: 55px 0px;
	}
}

.fa.footer-contacts-icon, .social-links a {
  background-color: #33383b;
  text-align: center;
}

.footer-navigation {
  h3 {
    margin: 0 0 20px;
    font: 400 36px Cookie,cursive;
    color: #fff;
    a {
      text-decoration: none;
      color: #fff;
    }
    span {
      color: #5383d3;
    }
  }
  p {
    &.links a {
      color: #fff;
      text-decoration: none;
    }
    &.company-name {
      color: #8f9296;
      font-size: 14px;
      font-weight: 400;
      margin-top: 20px;
    }
  }
}

@media (max-width: 767px) {
  .footer-contacts {
    margin: 30px 0;

  }
}

.footer-contacts p{
  display: inline-block;
  color: #fff;
  vertical-align: middle;
  a {
    text-decoration: none;
		color:white;
		font-weight: 400;
  }
}

.fa.footer-contacts-icon {
  color: #fff;
  font-size: 18px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  line-height: 38px;
  margin: 10px 0px 10px 0;
}

span.new-line-span {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 2;
}
.footer-contacts {
	h4 {
		display: block;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 20px;
	}
}
.footer-about {
  h4 {
    display: block;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  p {
    line-height: 20px;
    color: #92999f;
    font-size: 13px;
    font-weight: 400;
    margin: 0;
  }
}

div.social-links {
  margin-top: 20px;
  color: #fff;
}

.social-links a {
  display: inline-block;
  width: 35px;
  height: 35px;
  cursor: pointer;
  border-radius: 2px;
  font-size: 20px;
  color: #fff;
  line-height: 35px;
  margin-right: 5px;
  margin-bottom: 5px;
}

div.row {
  padding: 50px 0;
}

.navbar {
  margin-bottom: 0;
  border: none;
	.navbar-right{
		padding: 20px 0;
		@media(max-width:767px){
			padding: 5px 0;
		}
	}
	.navbar-header{
		@media(max-width: 767px){
			height: 80px;
		}
	}
	.logo {
		@media(max-width:767px){
			max-height: 50px;
		}
	}
}
.navbar-toggle {
	margin-top: 22px;
}
.navbar-brand .glyphicon {
  margin-right: 6px;
}

.hero {
  background: url(../img/city_bg.jpg) center no-repeat;
  background-size: cover;
  border: none;
}

@media (min-width: 992px) {
  .hero .get-it {
    text-align: right;
    margin-top: 80px;
    padding-right: 30px;
  }
}

.icon-feature, .site-footer, .testimonials blockquote {
  text-align: center;
}

@media (max-width: 992px) {
  .hero {
    .get-it, .phone-preview {
      text-align: center;
    }
  }
}

.hero .get-it {
  h1, p {
    color: #fff;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
    margin-bottom: 40px;
  }
  .btn {
    margin-left: 10px;
    margin-bottom: 10px;
    text-shadow: none;
  }
}

div.iphone-mockup {
  position: relative;
  max-width: 250px;
  margin: 20px;
  display: inline-block;
}

.iphone-mockup {
  img.device {
    width: 100%;
    height: auto;
  }
  .screen {
    position: absolute;
    width: 88%;
    height: 77%;
    top: 12%;
    border-radius: 2px;
    left: 6%;
    border: 1px solid #444;
    overflow: hidden;
    background: url(../img/screen-content-iphone-6.jpg) center;
    background-size: cover;
    &:before {
      content: '';
      background-color: #fff;
      position: absolute;
      width: 70%;
      height: 140%;
      top: -12%;
      right: -60%;
      transform: rotate(-19deg);
      opacity: .2;
    }
  }
}

.icon-feature .glyphicon {
  font-size: 60px;
}
.jumbotron p {
	@media(max-width:767px){
		font-size: 1em;
	}
}
.jumbotron h1 {
	@media(max-width:767px){
		font-size: 2em;
	}
}

section.features {
  background-color: #eaeaea;
  padding: 80px 0;
  color: #333;
}

.features {
	.col-md-12 {
		padding-bottom: 50px;
	}
	.trio {
		margin-top: 50px;
	}
  h2 {
		font-size: 2em;
		text-transform: uppercase;
		font-family: inherit;
  }
  i {
		background: #333;
		color: #fff;
		padding: 50px;
		border-radius:100%;
		font-size:3em;
	}
	p {
		font-size: 1.1em;
	}
}

section.testimonials {
  // margin: 80px 0;
	padding:80px 0;
	p{
		font-size: 1.3em;
	}
}

.site-footer {
  padding: 20px 0;
  h5 {
    color: inherit;
    font-size: 20px;
  }

  .social-icons a {
    &:hover {
      opacity: 1;
    }
    display: inline-block;
    width: 32px;
    border: none;
    font-size: 20px;
    border-radius: 50%;
    margin: 4px;
    color: #fff;
    text-align: center;
    background-color: #798FA5;
    height: 32px;
    opacity: .8;
    line-height: 32px;
  }
}

@media (min-width: 768px) {
  .site-footer h5 {
    text-align: left;
  }
}

@media (min-width: 768px) {
  .site-footer .social-icons {
    text-align: right;
  }
}
